import { useState, useEffect } from 'react'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query'
import { useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import {
  acceptTerms,
  createMask,
  listMaskUsers,
  partialUpdateUser,
  uploadAvatarUpdate,
  passwordResetConfirm,
  passwordResetCreate,
  retrieveUser,
  activate2FA,
  confirm2FA,
  deactivate2FA,
  requestLogin,
  createLoginCode,
  removeAvatar,
  resendCodeMFA,
  passwordResetValidateToken,
  change2FaMethod,
  retrieveUserFeatures,
} from '../api'
import {
  ILoginResponse,
  IError,
  ILoginInput,
  IUserRequest,
  IUser,
  INormalLoginResponse,
  IDecodedToken,
  I2FAConfirmationResponse,
  I2FAResendCodeRequest,
  IToken,
  IUserFeatures,
} from '../interface'
import { getAccessToken, performValidation, saveMaskAuthToStorage } from '../../utils/auth'

export const AuthQueryKeys = {
  GET_USER: 'GET_USER',
  GET_USER_FEATURES: 'GET_USER_FEATURES',
  GET_MASK_USERS: 'GET_MASK_USERS',
}

// Authentication Hooks

export const useAuthentication = () => {
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    async function requestAuthentication() {
      setLoading(true)
      const result = await performValidation()
      setIsAuthenticated(result)
      setLoading(false)
    }
    requestAuthentication()
  }, [location.pathname])

  return { loading, isAuthenticated }
}

export const use2FACheck = (isAuthenticated: boolean): boolean => {
  let result = false
  if (isAuthenticated) {
    const accessToken = getAccessToken()
    try {
      const { mfa_enabled, mfa_required } = jwtDecode<IDecodedToken>(accessToken)
      if (!mfa_enabled && mfa_required) {
        result = true
      }
    } catch (e) {
      //
    }
  }
  return result
}

export const useLoginRequest = (
  config?: UseMutationOptions<ILoginResponse, IError, ILoginInput>
) => {
  const { isLoading, isError, mutate: signIn, error } = useMutation<
    ILoginResponse,
    IError,
    ILoginInput
  >(requestLogin, config)

  return { signIn, isLoading, error, isError }
}

export const useCreateLoginCode = (
  config?: UseMutationOptions<
    INormalLoginResponse,
    IError,
    { ephemeral_token: string; code: string }
  >
) =>
  useMutation<INormalLoginResponse, IError, { ephemeral_token: string; code: string }>(
    createLoginCode,
    config
  )

export const useMaskUsers = (page: number, search: string, pageSize?: number) =>
  useQuery([AuthQueryKeys.GET_MASK_USERS, { page, search }], () =>
    listMaskUsers(page, search, pageSize)
  )

export const useCreateMask = () =>
  useMutation<INormalLoginResponse, IError, { guid: string }>(createMask, {
    onSuccess: (data) => {
      saveMaskAuthToStorage(data)
      window.location.href = '/'
    },
  })

export const useRetrieveUser = (guid: string) =>
  useQuery([AuthQueryKeys.GET_USER, guid], () => retrieveUser(guid), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

export const useRetrieveUserFeatures = (
  guid: string | undefined,
  config?: UseQueryOptions<IUserFeatures[], IError, IUserFeatures[]>
) =>
  useQuery([AuthQueryKeys.GET_USER_FEATURES, guid], () => retrieveUserFeatures(guid), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    enabled: !!guid,
    ...config,
  })

export const usePartialUpdateUser = () =>
  useMutation<IUser, IError, { guid: string; userRequest: IUserRequest }>(partialUpdateUser)

export const useUploadAvatar = () => {
  const queryClient = useQueryClient()

  return useMutation<undefined, IError, { guid: string; file: any }>(uploadAvatarUpdate, {
    onSuccess: async () => {
      await queryClient.refetchQueries([AuthQueryKeys.GET_USER, 'me'])
    },
  })
}

export const useRemoveAvatar = () => {
  const queryClient = useQueryClient()

  return useMutation<undefined, IError, { guid: string }>(removeAvatar, {
    onSuccess: async () => {
      await queryClient.refetchQueries([AuthQueryKeys.GET_USER, 'me'])
    },
  })
}

export const usePasswordReset = () =>
  useMutation<undefined, IError, { email: string }>(passwordResetCreate)

export const usePasswordResetConfirm = () =>
  useMutation<IToken, IError, { password: string; token: string }>(passwordResetConfirm)

export const usePasswordResetValidateToken = () =>
  useMutation<IToken, IError, { token: string }>(passwordResetValidateToken)

export const use2FAActivate = () =>
  useMutation<undefined, IError, { method: string; value: string }>(activate2FA)

export const useChange2FAMethod = () =>
  useMutation<undefined, IError, { method: string }>(change2FaMethod)

export const use2FAConfirm = (
  config?: UseMutationOptions<
    I2FAConfirmationResponse,
    IError,
    { code: string; method: string; refresh: string }
  >
) =>
  useMutation<I2FAConfirmationResponse, IError, { code: string; method: string; refresh: string }>(
    confirm2FA,
    config
  )

export const use2FADeactivate = (
  config?: UseMutationOptions<
    undefined,
    IError,
    {
      method: string
    }
  >
) => useMutation<undefined, IError, { method: string }>(deactivate2FA, config)

export const use2FAResendCode = (
  config?: UseMutationOptions<undefined, IError, I2FAResendCodeRequest>
) => useMutation<undefined, IError, I2FAResendCodeRequest>(resendCodeMFA, config)

export const useAcceptTerms = () => useMutation<undefined, IError>(acceptTerms)
