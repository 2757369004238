import React from 'react'
import { use2FAActivate } from '../../../../service/hooks/auth'
import PhoneInput from 'react-phone-number-input'
import CustomDialog from '../../../../components/CustomDialog'
import { Box, Button, Typography } from '@mui/material'
import CustomPhoneInput from '../../../../components/CustomPhoneInput'
import { TwoFASetupStepProps } from './interface'
import ErrorAlert from '../../../../components/ErrorAlert'
import { E2FAMethod } from '../../../../service/enum'

const PhoneNumberStep: React.FC<TwoFASetupStepProps> = ({
  open,
  onClose,
  onBack,
  onNext,
  faValue,
  setFaValue,
  faMethod,
}) => {
  const { mutateAsync: requestActivate, error, isLoading } = use2FAActivate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    if (faMethod === E2FAMethod.sms) {
      await requestActivate({
        method: faMethod,
        value: faValue,
      })
    } else {
      await requestActivate({
        value: faValue,
        method: faMethod,
      })
    }
    onNext()
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      buttonCloseOnly
      title="Two Factor Authentication Setup"
      contentDividers
      formOnSubmit={(e) => handleSubmit(e)}
      actions={
        <>
          <Button variant="outlined" onClick={onBack}>
            Cancel
          </Button>
          <Button variant="contained" onClick={(event) => handleSubmit(event)} disabled={isLoading}>
            Next
          </Button>
        </>
      }
    >
      {faMethod === E2FAMethod.sms ? (
        <>
          <Typography fontSize={14} fontWeight={350} mb={1}>
            Please input your phone number to verify
          </Typography>
          <PhoneInput
            placeholder="Enter phone number"
            defaultCountry="US"
            value={faValue}
            onChange={setFaValue}
            inputComponent={CustomPhoneInput}
          />
          <Box mt={1}>{error && <ErrorAlert error={error} />}</Box>
        </>
      ) : (
        <>
          <Typography fontSize={14} fontWeight={350} mb={1}>
            Email to verify: {faValue}
          </Typography>
        </>
      )}
    </CustomDialog>
  )
}

export default PhoneNumberStep
