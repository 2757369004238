import { IValueTitle } from './interface'

export enum ERole {
  super_admin = 'super_admin',
  admin = 'admin',
  user = 'user',
  client = 'client',
  designee = 'designee',
  observer = 'observer',
}

export enum EPermissionGroup {
  poa = 'POA Approval',
  associate = 'Associate',
  lead = 'Lead',
  supervisor = 'Supervisor',
  manager = 'Manager',
}

export enum EInvitationStatus {
  pen = 'pen',
  acc = 'acc',
  rev = 'rev',
}

export enum EWorkflowState {
  client_approval = 'client_approval',
  org_approval = 'org_approval',
  payment_pending = 'payment_pending',
  verbal_approval = 'verbal_approval',
  poa_approval = 'poa_approval',
  paid = 'paid',
  rejected = 'rejected',
  pre_bill = 'pre_bill',
  changes_required = 'changes_required',
}

export enum EWorkflowEventAction {
  approved = 'approved',
  rejected = 'rejected',
  auto_approved = 'not_needed',
}

export enum EBillAuditTrailEventType {
  bill_created = 'bill_created',
  bill_auto_approved = 'bill_auto_approved',
  bill_updated = 'bill_updated',
  bill_workflow_changed = 'bill_workflow_changed',
  attachment_deleted = 'attachment_deleted',
}

export enum EBillAction {
  approve = 'approve',
  reject = 'reject',
  revise = 'revise',
}

export enum EApprovalCriteria {
  recurring = 'recurring',
  client_approval = 'client_approval',
}

export enum EBodyType {
  text = 'text',
  html = 'html',
}

export enum EPaymentMethod {
  ach = 'ach',
  check = 'check',
  eft = 'eft',
  wire = 'wire',
  auto = 'auto',
}

export const paymentMethodMap = {
  wire: 'Wire',
  eft: 'EFT',
  check: 'Check',
  ach: 'ACH',
  auto: 'Autopay',
}

export enum EReceiptMethod {
  client = 'client',
  mail_scan = 'mail_scan',
  online = 'online',
  vendor = 'vendor',
  no_invoice = 'no_invoice',
  other = 'other',
}

export const receiptMethodMap = {
  client: 'Client',
  mail_scan: 'Mail Scan',
  online: 'Online',
  vendor: 'Vendor email',
  no_invoice: 'No invoice template',
  other: 'Other',
  '': '-',
}

export enum EPermission {
  approve_org_approval = 'approve_org_approval',
  approve_client_approval = 'approve_client_approval',
  approve_verbal_approval = 'approve_verbal_approval',
  approve_payment_pending = 'approve_payment_pending',
  approve_poa = 'approve_poa',
  approve_wire = 'approve_wire',
  approve_ach = 'approve_ach',
  approve_check = 'approve_check',
  approve_eft = 'approve_eft',
}

export enum E2FAMethod {
  sms = 'sms_twilio',
  mobile_app = 'mobile_app',
  yubikey = 'yubikey',
  email = 'email',
}

export enum EPasswordLinkType {
  reset = 'reset',
  invite = 'invite',
}

export enum EReportType {
  check_registers = 'check_registers',
  cash_flow = 'cash_flow',
  financial_statements = 'financial_statements',
  custom = 'custom',
  printed_checks = 'printed_checks',
  reconciliation = 'reconciliation',
}

export enum ENotificationType {
  email = 'email',
  push = 'push',
}

export enum EFrequency {
  weekly = 'weekly',
  bi_weekly = 'bi_weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

export enum EReminderFrequency {
  weekly = 'weekly',
  monthly = 'monthly',
  quarterly = 'quarterly',
  yearly = 'yearly',
}

export enum EReminderHistoryStatus {
  report_unarchived = 'report_unarchived',
  report_archived = 'report_archived',
  report_skipped = 'report_skipped',
  created = 'created',
}

export enum ELoginError {
  no_client = 'no_client',
}

export enum EClientStatus {
  pending = 'pending',
  active = 'active',
  accepted = 'accepted',
  pending_invite = 'pending_invite',
}

export const clientStatusMap = {
  pending: 'Pending',
  active: 'Active',
  accepted: 'Invite Accepted',
  pending_invite: 'Pending Invite',
}

export enum ETaskStatus {
  in_progress = 'in_progress',
  ready = 'ready',
  completed = 'completed',
  submitted = 'submitted',
  blocked = 'blocked',
}

export const taskStatusMap = {
  in_progress: 'In progress',
  completed: 'Completed',
  submitted: 'Submitted',
  blocked: 'Blocked',
  ready: 'Ready To Start',
}

export enum EPersonRoles {
  division_head = 'division_head',
  it = 'it',
  accounting = 'accounting',
  product_manager = 'product_manager',
  plumb_manager = 'plumb_manager',
}

export const assignedRolesMap = {
  division_head: 'Division Head',
  it: 'IT',
  accounting: 'Accounting',
  product_manager: 'App Product Manager',
  manager: 'Manager',
  associate: 'Associate',
  lead: 'Lead',
  vp_operations: 'VP Operations',
  admin: 'Admin',
}

export enum EPlumbService {
  bill_pay = 'bill_pay',
  family_office_accounting = 'family_office_accounting',
}

export enum EConnectionType {
  client = 'client',
  professional_advisor = 'professional_advisor',
}

export const connectionTypeMap = {
  client: 'Client',
  professional_advisor: 'Professional Advisor',
}

export const clientRelationMap = {
  primary: 'Primary',
  significant_other_spouse: 'Significant Other/Spouse',
  dependant_child: 'Dependent/Child',
  designee: 'Designee',
  other: 'Other',
  cpa: 'CPA',
  attorney: 'Attorney',
  wealth_manager: 'Wealth Manager /Team',
  family_office: 'Family Office Team',
}

export const propertyTypeMap = {
  rental: 'Rental',
  primary: 'Primary',
  vacation: 'Vacation',
  secondary: 'Secondary',
  other: 'Other',
}

export const servicesMap = {
  cpa: 'CPA',
  attorney: 'Attorney',
  wealth_manager: 'Wealth Manager/Team',
  family_office: 'Family Office Team',
}

export enum EOnboardingRole {
  it = 'it',
  accounting = 'accounting',
  product_manager = 'product_manager',
  manager = 'manager',
  associate = 'associate',
  lead = 'lead',
}

export enum ETaskListId {
  prelim = 8,
  entities = 9,
  properties = 48,
  app_users = 49,
  contacts = 50,
  banks = 51,
  summary = 52,
}

export const completeLinkTabList: IValueTitle[] = [
  {
    value: 'initial',
    title: 'Anchor to Initial Checklist',
  },
  {
    value: 'client_details',
    title: 'Anchor to Client Details',
  },
  {
    value: 'it',
    title: 'Anchor to IT',
  },
  {
    value: 'prelim',
    title: 'Anchor to Prelim. Qs',
  },
  {
    value: 'entities',
    title: 'Anchor to Entities',
  },
  {
    value: 'commitment',
    title: 'Anchor to Commitment & Report Reminders',
  },
  {
    value: 'other',
    title: 'Anchor to Other',
  },
  {
    value: 'docs',
    title: 'Anchor to Docs',
  },
  {
    value: 'team',
    title: 'Anchor to Team',
  },
  {
    value: 'contacts',
    title: 'Anchor to Contacts',
  },
  {
    value: 'banks',
    title: 'Anchor to Banks',
  },
  {
    value: 'properties',
    title: 'Anchor to Properties',
  },
  {
    value: 'app_users',
    title: 'Anchor to App Users',
  },
  {
    value: 'summary',
    title: 'Anchor to Summary',
  },
]

export const mfaMethodMap = {
  sms: 'SMS',
  email: 'Email',
}

export interface IApprovalCriteria {
  value: string
  title: string
}

export const approvalCriteria: IApprovalCriteria[] = [
  {
    value: 'client_approval',
    title: 'Client Approval',
  },
  {
    value: 'recurring',
    title: 'Recurring',
  },
]
