import React, { useContext, useState } from 'react'
import { Link as LinkRouter, useHistory } from 'react-router-dom'
import clsx from 'clsx'
import {
  Avatar,
  Button,
  CircularProgress,
  List,
  ListItemButton,
  Menu,
  Typography,
  Link,
  Box,
  styled,
} from '@mui/material'
import { useRetrieveUser } from '../../../service/hooks/auth'
import { clearMaskAuthStorage, getMaskAccessToken } from '../../../utils/auth'
import HeaderDots from '../HeaderDots'
import { ClientGuidContext } from '../../../contexts/ClientGuidContext'
import lineBorder from '../../../assets/images/line_border.png'
import { useClientGlobalBillMessageSummary } from '../../../service/hooks/clientBill'
import { logout } from '../../../utils/auth'
import { useClientOutlookStatus } from '../../../service/hooks/integrations'
import { getClientEmailAddress } from '../../../utils/helpers'
import SearchIcon from '@mui/icons-material/Search'
import { PLUMB_EMAIL } from '../../../utils/constants'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

export const StyledButton = styled(Button)(() => ({
  marginLeft: '12px !important',
  padding: '15px 8px !important',
  color: '#fff',
  position: 'relative',
  transform: 'none !important',
  '&.active': {
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      backgroundColor: 'white',
      left: 0,
      bottom: 0,
    },
    '& .MuiSvgIcon-root': {
      opacity: 1,
    },
  },
  '&:hover': {
    background: 'rgba(255,255,255,0.08)',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '24px',
    opacity: 0.3,
  },
}))

const ClientHeaderUserBox: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { clientGuid, isOnboardingCompleted } = useContext(ClientGuidContext)
  const { data: message } = useClientGlobalBillMessageSummary(clientGuid)
  const { data: userInfo, isLoading } = useRetrieveUser('me')
  const { data: clientOutlook } = useClientOutlookStatus(clientGuid)
  const clientEmailAddr = getClientEmailAddress(clientOutlook?.email_address || PLUMB_EMAIL)
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUnmask = () => {
    handleClose()
    clearMaskAuthStorage()
    window.location.href = '/'
  }

  const handleLogOut = () => {
    handleClose()
    logout()
  }

  const isMaskedUser = !!getMaskAccessToken()

  return (
    <>
      <Box display="flex" justifyContent="start" alignItems="center">
        {isOnboardingCompleted === '1' && (
          <>
            <Button onClick={() => history.push('/advanced-search')}>
              <SearchIcon sx={{ color: 'white' }} />
            </Button>
            <HeaderDots unreadAmount={message?.unread_count || 0} guid={clientGuid} />
            <Box>
              <img alt="line border" src={lineBorder} />
            </Box>
          </>
        )}
        <StyledButton
          variant="text"
          onClick={handleClick}
          disableRipple
          endIcon={anchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          className={clsx({ active: anchorEl })}
        >
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <>
              <Box>
                <Typography color="white">
                  {`${userInfo?.first_name} ${userInfo?.last_name}`}
                </Typography>
              </Box>
              <Box pl={2}>
                <Avatar src={userInfo?.avatar}>{userInfo?.first_name.charAt(0)}</Avatar>
              </Box>
            </>
          )}
        </StyledButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}
      >
        <Box>
          <List component="div">
            {isOnboardingCompleted === '1' && (
              <>
                <LinkRouter to="/profile" className="nav-link-simple">
                  <ListItemButton onClick={handleClose}>
                    <Typography color="black">Profile</Typography>
                  </ListItemButton>
                </LinkRouter>
                <LinkRouter to="/support" className="nav-link-simple">
                  <ListItemButton onClick={handleClose}>
                    <Typography color="black">Support</Typography>
                  </ListItemButton>
                </LinkRouter>
              </>
            )}
            <Link href={`mailto:${clientEmailAddr}`} sx={{ textDecoration: 'none' }}>
              <ListItemButton>
                <Typography color="black">Email your Plumb Team</Typography>
              </ListItemButton>
            </Link>
            {isMaskedUser && (
              <ListItemButton onClick={handleUnmask}>
                <Typography color="black">Unmask</Typography>
              </ListItemButton>
            )}
            <ListItemButton onClick={handleLogOut}>Logout</ListItemButton>
          </List>
        </Box>
      </Menu>
    </>
  )
}

export default ClientHeaderUserBox
